import React, { useContext } from "react";

import Circle from "@mui/icons-material/Circle";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { Check, Bell, ArrowUpRight } from "react-feather";
import { makeStyles } from "tss-react/mui";

import { PoolResponse } from "@cloudentity/acp-identity";
import { IDP } from "@cloudentity/acp-root";

import saLogo from "../../../../assets/images/logos/sa/sa_rgb_mark_blue.svg";
import Button from "../../../../common/components/Button";
import { openUrlInNewTab } from "../../../../common/utils/browser.utils";
import { GlobalToursContext } from "../../../GlobalTours/GlobalTours";
import {
  GET_STARTED_MAIN_USE_CASE_TOUR,
  GetStartedMainUseCaseTourSteps,
} from "../../../GlobalTours/Tours/GetStartedMainUseCaseTour";
import { useWorkspace } from "../../common/useWorkspace";
import IdentitiesListSimplePreview from "../../identities/identities_view_tabbed/identitiesListSimple/IdentitiesListSimplePreview";
import blixitLogo from "../img/blixit-logo.svg";
import { SmallInfoText, Steps, useCommonStyles } from "../utils";
import ProgressIndicator from "./ProgressIndicator";

const useStyles = makeStyles()(theme => ({
  container: {
    padding: 32,
  },
  right: {
    flex: 1,
  },
  stepper: {
    "& .MuiStepConnector-root": {
      marginLeft: 15,
      "& .MuiStepConnector-line": {
        borderColor: theme.custom.sa.complimentary.green.high,
        borderWidth: 2,
      },
    },
  },
  iconContainer: {
    border: `solid 2px ${theme.palette.primary.main}`,
    borderRadius: 32,
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 8,
    boxShadow: "0px 0px 0px 4px #EBEDFF",
  },
  iconContainerGreen: {
    borderColor: theme.custom.sa.complimentary.green.high,
    boxShadow: "none",
  },
  icon: {
    color: theme.custom.sa.complimentary.green.high,
    width: 20,
  },
  infoBox: {
    borderRadius: 8,
    border: "1px solid",
    borderColor: theme.palette.primary.main,
    background: "linear-gradient(90deg, #F2F5FF 0%, #FFFFFF 100%)",
    display: "flex",
    padding: 16,
    gap: 16,
    margin: "24px 0 32px",
  },
  infoBoxIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 40,
    height: 40,
    padding: 10,
    borderRadius: 8,
    border: "1px solid",
    borderColor: theme.custom.sa.greys.g40,
    background: theme.custom.sa.greys.g0,
    boxShadow: "0px 0px 0px 2px #EBEDFF",
  },
  buttonsContainer: {
    margin: "16px 0 64px",
    display: "flex",
    gap: 16,
    alignItems: "normal",
  },
  paperButton: {
    flex: 1,
    backgroundColor: theme.custom.sa.neutrals.n0,
    borderColor: theme.custom.sa.greys.g40,
    borderRadius: 4,
    display: "flex",
    flexDirection: "column",
    "&:hover": {
      cursor: "pointer",
      boxShadow: theme.custom.shadows.surfaceHover,
    },
  },
  paperButtonFooter: {
    padding: 8,
    display: "flex",
    justifyContent: "end",
  },
}));

const steps = [
  {
    label: "Configure authentication",
    description: "You have set passkey authentication as your primary authentication method.",
  },
  {
    label: "Invite users",
    description: "You have invited a user to register for the passwordless experience.",
  },
  {
    label: "Experience sign-in journey",
    description: "with Blixit demo application.",
  },
];

interface Props {
  poolIdps: IDP[];
  externalIdps: IDP[];
  pool: PoolResponse | undefined;
  links: { demo: string | null; user: string | null };
  onChangeStep: (step: Steps) => void;
}

export default function Explore({ poolIdps, externalIdps, pool, links, onChangeStep }: Props) {
  const { classes: commonClasses } = useCommonStyles();
  const { cx, classes } = useStyles();
  const [workspace] = useWorkspace();
  const globalToursContext = useContext(GlobalToursContext);

  function handleExit() {
    globalToursContext.register({
      id: GET_STARTED_MAIN_USE_CASE_TOUR,
      runOnce: false,
      startOnRegister: true,
      steps: GetStartedMainUseCaseTourSteps,
    });
  }

  return (
    <>
      <div className={commonClasses.leftWithPreview}>
        <div className={classes.container}>
          <div className={commonClasses.intro}>
            <Typography variant="h2" className={commonClasses.header}>
              Explore the Sign-In Experience
            </Typography>
            <SmallInfoText fontSize={14}>Your user sign-in setup is ready</SmallInfoText>
            <ProgressIndicator value={100} text="3" caption="Done" />

            <Divider className={commonClasses.divider} />
          </div>

          <Stepper activeStep={3} orientation="vertical" className={classes.stepper}>
            {steps.map((step, i) => (
              <Step key={step.label}>
                <StepLabel
                  optional={<Typography variant="caption">{step.description}</Typography>}
                  StepIconComponent={i === steps.length - 1 ? LastStepIcon : StepIcon}
                >
                  <Typography variant="h5">{step.label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>

          <div className={classes.infoBox}>
            <div className={classes.infoBoxIcon}>
              <Bell />
            </div>
            <div>
              <Typography variant="textMD" component="div" style={{ marginBottom: 8 }}>
                <b>We have sent you an email</b>
              </Typography>
              <Typography variant="textMD" component="div">
                Please check your email and compete the registration process before you continue
                with the experience.
              </Typography>
            </div>
          </div>

          <Typography variant="h3">It's time to test your Launch</Typography>

          <SmallInfoText>
            To experience the end-user sign-in process, please click the launch buttons below for
            the demo application and the self-service user portal.
          </SmallInfoText>

          <div className={cx(commonClasses.flexContainer, classes.buttonsContainer)}>
            {links.demo && (
              <Paper
                onClick={() => {
                  openUrlInNewTab(links.demo ?? "");
                }}
                className={classes.paperButton}
              >
                <div style={{ padding: 24 }}>
                  <Avatar src={blixitLogo} alt="logo" />
                  <Typography variant="h4" style={{ margin: "12px 0 16px", fontWeight: 500 }}>
                    Test it just like your business app!
                  </Typography>
                  <SmallInfoText>
                    View all configured authentication options by signing into our Blixit Demo as an
                    end user.
                  </SmallInfoText>
                </div>
                <div style={{ flex: 1 }} />
                <Divider />
                <div className={classes.paperButtonFooter}>
                  <Button variant="text" onClick={() => {}}>
                    Test experience
                    <ArrowUpRight size={18} style={{ marginLeft: 8 }} />
                  </Button>
                </div>
              </Paper>
            )}

            {links.user && (
              <Paper
                onClick={() => {
                  openUrlInNewTab(links.user ?? "");
                }}
                className={classes.paperButton}
              >
                <div style={{ padding: 24 }}>
                  <Avatar src={saLogo} alt="logo" />
                  <Typography variant="h4" style={{ margin: "12px 0 16px", fontWeight: 500 }}>
                    Self-Service Portal
                  </Typography>
                  <SmallInfoText>
                    Empower users to manage accounts, permissions, and security with a secure,
                    scalable, and intuitive portal.
                  </SmallInfoText>
                </div>
                <div style={{ flex: 1 }} />
                <Divider />
                <div className={classes.paperButtonFooter}>
                  <Button variant="text" onClick={() => {}}>
                    Test experience
                    <ArrowUpRight size={18} style={{ marginLeft: 8 }} />
                  </Button>
                </div>
              </Paper>
            )}
          </div>

          <div className={commonClasses.flexContainer}>
            <Button
              variant="text"
              onClick={() => onChangeStep(Steps.MAIN_USE_CASE_REGISTER_USER)}
              color="inherit"
              className={commonClasses.backButton}
            >
              Back
            </Button>
            <Button variant="outlined" onClick={handleExit}>
              Exit
            </Button>
          </div>
        </div>
      </div>
      <div className={cx(commonClasses.right, classes.right)}>
        <IdentitiesListSimplePreview
          workspace={workspace}
          poolIdps={poolIdps}
          externalIdps={externalIdps}
          pool={pool}
        />
      </div>
    </>
  );
}

function StepIcon() {
  const { cx, classes } = useStyles();

  return (
    <div className={cx(classes.iconContainer, classes.iconContainerGreen)}>
      <Check className={classes.icon} strokeWidth={3} />
    </div>
  );
}

function LastStepIcon() {
  const { classes } = useStyles();

  return (
    <div className={classes.iconContainer}>
      <Circle color="primary" style={{ width: 12 }} />
    </div>
  );
}
