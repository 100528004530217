import React, { useContext } from "react";
import { useNavigate } from "react-router";

import { BasicPopoverStep } from "../../../common/components/guidedTours/BasicPopoverStep";
import {
  StepActions,
  StepContent,
  StepTitle,
} from "../../../common/components/guidedTours/StepComponents";
import { useWorkspace } from "../../components/common/useWorkspace";
import { GlobalToursContext } from "../GlobalTours";

export const GET_STARTED_MAIN_USE_CASE_TOUR = "get-started-main-use-case-tour";

export enum GetStartedMainUseCaseTourStep {
  OverviewNavCard = "OverviewNavCard",
  ApplicationsNavCard = "ApplicationsNavCard",
  UsersNavCard = "UsersNavCard",
  AppearanceNavCard = "AppearanceNavCard",
  RestartTourCard = "RestartTourCard",
  QuickstartNavCard = "QuickstartNavCard",
}

type CardContentType = {
  step: string;
  title: string;
  description: string;
  withSkip?: boolean;
};

const cardContent: { [key in GetStartedMainUseCaseTourStep]: CardContentType } = {
  [GetStartedMainUseCaseTourStep.OverviewNavCard]: {
    step: "1",
    title: "This is your overview",
    description:
      "You can always view a visual topology of all your registered business applications and their access pathways.",
    withSkip: true,
  },
  [GetStartedMainUseCaseTourStep.ApplicationsNavCard]: {
    step: "2",
    title: "Add new application",
    description:
      "Register and configure your applications here for user login, single-sign on (SSO), and access control",
  },
  [GetStartedMainUseCaseTourStep.UsersNavCard]: {
    step: "3",
    title: "User Management",
    description:
      "Configure the built in authentication methods and also configure user attributes. The Identity Pool offers a highly configurable, JSON schema-based, persistent, hyper-scalable storage solution for user attributes.",
  },
  [GetStartedMainUseCaseTourStep.AppearanceNavCard]: {
    step: "4",
    title: "Brand Customer-Facing Pages",
    description:
      "Customize the login interface and other components, such as email templates and self-service screens, that customer users interact with within the service to reflect your brand's identity.",
  },
  [GetStartedMainUseCaseTourStep.RestartTourCard]: {
    step: "5",
    title: "Restart the Tour",
    description: "Relaunch the Getting Started experience at any time",
  },
  [GetStartedMainUseCaseTourStep.QuickstartNavCard]: {
    step: "6",
    title: "Access quickstart",
    description:
      "A set of instructions and resources designed to quickly set up and begin using our platform capabilities with minimal effort.",
    withSkip: true,
  },
};

export const GetStartedMainUseCaseTourSteps = [
  {
    id: GetStartedMainUseCaseTourStep.OverviewNavCard,
    targets: () => [document.querySelector<HTMLElement>("#overview")],
  },
  {
    id: GetStartedMainUseCaseTourStep.ApplicationsNavCard,
    targets: () => [document.querySelector<HTMLElement>("#applications")],
  },
  {
    id: GetStartedMainUseCaseTourStep.UsersNavCard,
    targets: () => [document.querySelector<HTMLElement>("#users-link")],
  },
  {
    id: GetStartedMainUseCaseTourStep.AppearanceNavCard,
    targets: () => [document.querySelector<HTMLElement>("#appearance")],
  },
  {
    id: GetStartedMainUseCaseTourStep.RestartTourCard,
    targets: () => [document.querySelector<HTMLElement>("#getting-started-link")],
  },
  {
    id: GetStartedMainUseCaseTourStep.QuickstartNavCard,
    targets: () => [document.querySelector<HTMLElement>("#quickstart")], // FIXME
  },
];

const RegularCardStepContent = ({
  totalCount,
  cardContent,
  onBack,
  onCompleteTour,
  onCompleteCurrentStep,
  moreLabel,
  nextLabel,
}: {
  totalCount: number;
  cardContent: CardContentType;
  onBack: () => void;
  onCompleteTour: () => void;
  onCompleteCurrentStep: () => void;
  moreLabel?: string;
  nextLabel?: string;
}) => {
  return (
    <>
      <StepTitle id={cardContent.step}>{cardContent.title}</StepTitle>
      <StepContent>{cardContent.description} </StepContent>
      <StepActions
        onPrev={!cardContent.withSkip ? onBack : undefined}
        onMore={cardContent.withSkip ? onCompleteTour : undefined}
        onNext={onCompleteCurrentStep}
        moreLabel={moreLabel}
        nextLabel={nextLabel}
        progressText={`${cardContent.step} of ${totalCount}`}
      />
    </>
  );
};

const GetStartedMainUseCaseTour = () => {
  const [workspace] = useWorkspace();
  const navigate = useNavigate();

  const tour = GET_STARTED_MAIN_USE_CASE_TOUR;

  const globalToursContext = useContext(GlobalToursContext);
  const currentTour = globalToursContext.getCurrentTour();
  const currentStep = globalToursContext.getCurrentStep();
  const popoverStyle = { width: 460, padding: 24 };

  function onGoToPreviousStep() {
    if (!currentTour || !currentStep) return;
    globalToursContext.goBack(currentTour, currentStep.id);
  }

  function onCompleteCurrentStep() {
    if (!currentTour || !currentStep) return;
    globalToursContext.complete(currentTour, currentStep.id);
  }

  function onCompleteTour() {
    if (!currentTour) return;
    globalToursContext.completeAndStopAll(currentTour);
  }

  const totalStepsCount = Object.keys(cardContent).length;
  const currentStepContent: CardContentType | null =
    (currentStep && cardContent[currentStep?.id]) || null;

  return currentTour === tour && currentStep && currentStepContent ? (
    <>
      <BasicPopoverStep
        targets={currentStep.targets}
        scrollToElement
        placement="right-start"
        cardStyles={popoverStyle}
        onMaskClick={onCompleteCurrentStep}
      >
        <RegularCardStepContent
          totalCount={totalStepsCount}
          cardContent={currentStepContent}
          onBack={onGoToPreviousStep}
          onCompleteTour={onCompleteTour}
          {...(currentStepContent.withSkip ? { moreLabel: "Skip" } : {})}
          {...(currentStep.id === GetStartedMainUseCaseTourStep.QuickstartNavCard
            ? {
                onCompleteCurrentStep: () => {
                  onCompleteCurrentStep();
                  navigate(`/${workspace}/quickstart`);
                },
                nextLabel: "Launch Quickstart",
              }
            : { onCompleteCurrentStep })}
        />
      </BasicPopoverStep>
    </>
  ) : null;
};

export default GetStartedMainUseCaseTour;
